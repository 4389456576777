<template>
  <div id="change-phone">
    <div
      :class="{
        visibleModal: slideChangePhoneIn,
        hiddenModal: !slideChangePhoneIn && changePhoneAllowedToSlideOut,
        initialHidenModal: !changeButtonPressed,
      }"
      id="change-phone-container"
      v-if="state == 'CHANGE'"
    >
      <div id="change-phone-label" class="green-brand">
        <font-awesome-icon @click="onCloseClick" id="close-icon" icon="times"></font-awesome-icon>
        <h4>{{ headerText }}</h4>
      </div>
      <div id="change-phone-form">
        <div id="phone-label">
          <h4>
            {{ newPhoneOrVerifyPhoneText }}
            <br />
            <span
              style="
                display: inline-block;
                color: #676767;
                font-size: 12px;
                width: 90%;
                margin: 0;
                margin-top: 5px;
                overflow-wrap: anywhere;
              "
            >{{ isSmsSentToUserAtOrderExecution && orderOnHold ? (isServiceWithDelayedResponse ? labels.cart.informViaSmsRegardingRequest[language] :labels.cart.informViaSmsRegardingOrder[language]) : labels.profile.phoneChange.willSendCode[language] }}</span>
          </h4>
        </div>
        <div id="phone-container">
          <vue-tel-input
            id="phone-input"
            defaultCountry="GR"
            @country-changed="countryChanged"
            @enter="change"
            @input="onPhoneInput"
            placeholder
            :validCharactersOnly="true"
            :enabledCountryCode="true"
            v-model="phone"
          ></vue-tel-input>
          <div v-if="changeSuccess == false" class="attemptFailedPhoneChange">
            <h6>{{ changeMessage[language] }}</h6>
          </div>
          <div v-else-if="changeSuccess == true" class="attemptSucceededPhoneChange">
            <h6>{{ changeMessage[language] }}</h6>
          </div>
          <Button
            :loading="buttonLoading"
            @click="change"
            id="change-phone-number-button"
          >{{ submitButtonText }}</Button>
        </div>
      </div>
    </div>

    <div
      :class="{
        visibleModal: slideChangePhoneIn,
        hiddenModal: !slideChangePhoneIn && changePhoneAllowedToSlideOut,
        initialHidenModal: !changeButtonPressed,
      }"
      id="verification-container"
      v-else-if="state == 'VERIFICATION'"
    >
      <div id="verification-label" class="green-brand">
        <font-awesome-icon @click="onCloseClick" id="close-icon" icon="times"></font-awesome-icon>
        <h4>{{ headerText }}</h4>
      </div>
      <div id="verification-form">
        <h4 id="sent-code-label">
          {{ labels.profile.phoneChange.codeSent[language] }}
          <br />
          <span id="verification-phone-number">+{{ dialCode }} {{ userPhone }}</span>
        </h4>
        <div id="verification-code">
          <input
            v-model="code"
            type="number"
            name="verification-code-input"
            id="verification-code-input"
            @keypress.enter="verify"
          />
          <Button
            :disabled="!hasVerificationCode"
            :loading="buttonLoading"
            @click="verify"
            id="verification-submit-button"
          >{{ submitButtonText }}</Button>
        </div>
        <div v-if="changeVerificationSuccess == false" class="attemptFailedVerification">
          <h6>{{ changeVerificationMessage[language] }}</h6>
        </div>
        <div v-else-if="changeVerificationSuccess == true" class="attemptSucceededVerification">
          <h6>{{ changeVerificationMessage[language] }}</h6>
        </div>
        <h5
          @click="changeNumber"
          class="extra-verification-option"
        >{{ labels.profile.phoneChange.changeNumber[language] }}</h5>
        <h5
          v-if="!resendCodeLoading"
          @click="resendCode"
          class="extra-verification-option"
        >{{ labels.profile.phoneChange.sendNewCode[language] }}</h5>
        <beat-loader
          v-else
          class="extra-verification-option"
          :loading="resendCodeLoading"
          :color="beatLoaderColor"
          :size="beatLoaderSize"
        ></beat-loader>
      </div>
    </div>
  </div>
</template>

<script>
import UserApi from "../api/user";
import Button from "./Button";
import BeatLoader from "vue-spinner/src/BeatLoader.vue";

export default {
  name: "ChangePhoneModal",
  components: {
    Button,
    BeatLoader,
  },
  props: ["isTempUserChange", "isSmsSentToUserAtOrderExecution", "isServiceWithDelayedResponse", "orderOnHold"],
  watch: {
    isChangePhoneOpen() {
      this.changeButtonPressed = true;
      this.changePhoneAllowedToSlideOut = true;
    },
  },

  computed: {
    isChangePhoneOpen() {
      return this.slideChangePhoneIn;
    },

    slideChangePhoneIn() {
      return this.$store.getters.getSlideChangePhoneIn;
    },

    slideChangePhoneOut() {
      return this.$store.getters.getSlideChangePhoneOut;
    },

    phone: {
      get() {
        return this.userPhone;
      },

      set(newPhone) {
        this.userPhone = newPhone;
      },
    },

    buttonLoading() {
      return this.changeButtonLoading || this.verifyButtonLoading;
    },

    changeSuccess() {
      return this.$store.getters.getChangePhoneSuccess;
    },

    changeMessage() {
      return this.$store.getters.getChangePhoneMessage;
    },

    changeVerificationSuccess() {
      return this.$store.getters.getChangeVerificationSuccess;
    },

    changeVerificationMessage() {
      return this.$store.getters.getChangeVerificationMessage;
    },

    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    submitButtonText() {
      return this.isTempUserChange
        ? this.labels.entrance.continue[this.language]
        : this.labels.profile.phoneChange.change[this.language];
    },

    headerText() {
      return this.isTempUserChange
        ? this.labels.entrance.completeOrder[this.language]
        : this.labels.profile.phoneChange.changePhone[this.language];
    },

    newPhoneOrVerifyPhoneText() {
      return this.isTempUserChange
        ? this.labels.profile.phoneChange.verifyPhone[this.language]
        : this.labels.profile.phoneChange.newPhone[this.language];
    },

    hasVerificationCode() {
      return this.code != undefined && this.code.trim() != "";
    },

    showDarkBrand() {
      this.$route.name;
      if (this.$route.name) {
        let routeName = this.$route.name;
        let darkProfileScreen = routeName.includes("profile") && this.$route.query && this.$route.query.brand == 'dark'
        let showDarkBrand = routeName.includes("services") || routeName.includes("menu") || routeName.includes("open-tables") || routeName.includes("open-rooms") || routeName.includes("store-order-history") || routeName.includes("cart") || routeName.includes("history") || routeName.includes("pin") || darkProfileScreen;
        return showDarkBrand;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      userPhone: "",
      dialCode: "",
      changePhoneAllowedToSlideOut: false,
      changeButtonLoading: false,
      verifyButtonLoading: false,
      resendCodeLoading: false,
      code: undefined,
      beatLoaderSize: "8px",
      beatLoaderColor: "black",
      changeButtonPressed: false,
      // available states: CHANGE, VERIFICATION
      state: "CHANGE",
    };
  },

  methods: {
    onSuccessfullVerification: function () {
      setTimeout(() => {
        this.onCloseClick();
        this.$emit("change");
      }, 350);
    },

    onCloseClick: function () {
      this.changeButtonLoading = false;
      this.verifyButtonLoading = false;

      this.$router.push({ name: "profile" });

      this.$store.dispatch("shouldSlideChangePhoneOut");

      this.$store.dispatch("shouldNotSlideChangePhoneIn");

      setTimeout(() => {
        this.$store.dispatch("resetChangePhoneState");
        this.$store.dispatch("setChangePhoneMessage", "");

        this.$store.dispatch("resetChangeVerificationState");
        this.$store.dispatch("setChangeVerificationMessage", "");

        this.state = "CHANGE";
        this.userPhone = "";
      }, 850);
    },

    changeNumber: function () {
      this.$store.dispatch("resetChangePhoneState");
      this.$store.dispatch("setChangePhoneMessage", "");
      this.state = "CHANGE";
    },

    resendCode: async function () {
      this.resendCodeLoading = true;
      let phoneNumberInParts = this.userPhone.split(" ");
      if (phoneNumberInParts[0] == `+${this.dialCode}`) {
        phoneNumberInParts.shift();
        this.userPhone = phoneNumberInParts.join(" ");
      }

      let phone = {
        phoneNumber: Number(this.userPhone.replace(/\D/g, "")),
        countryCode: Number(this.dialCode),
      };

      let response = await UserApi.changePhone(phone);

      this.$store.dispatch("setChangeVerificationMessage", response.message);

      if (response.status == 200)
        this.$store.dispatch("successfullChangeVerification");
      else this.$store.dispatch("failedChangeVerification");

      this.resendCodeLoading = false;
    },

    onPhoneInput() {
      if (this.changeSuccess == false) {
        this.$store.dispatch("setChangePhoneMessage", "");
      }
    },

    change: async function () {
      this.$store.dispatch("setChangePhoneMessage", "");
      if (this.userPhone == null || this.userPhone == undefined || this.userPhone.trim().length == 0) {
        let errorMessage = {
          GRE: "Εισάγετε το κινητό σας",
          ENG: "Please type your phone number"
        }
        this.$store.dispatch("setChangePhoneMessage", errorMessage);
        this.$store.dispatch("failedChangePhone");
        return;
      }

      this.changeButtonLoading = true;
      let phoneNumberInParts = this.userPhone.split(" ");
      if (phoneNumberInParts[0] == `+${this.dialCode}`) {
        phoneNumberInParts.shift();
        this.userPhone = phoneNumberInParts.join(" ");
      }

      let phone = {
        phoneNumber: Number(this.userPhone.replace(/\D/g, "")),
        countryCode: Number(this.dialCode),
      };

      let response = await UserApi.changePhone(phone);

      this.$store.dispatch("setChangePhoneMessage", response.message);

      if (response.status == 200) {
        this.$store.dispatch("successfullChangePhone");
        this.state = "VERIFICATION";
      } else {
        this.$store.dispatch("failedChangePhone");
      }

      this.changeButtonLoading = false;
    },

    verify: async function () {
      this.$store.dispatch("setChangeVerificationMessage", "");
      this.verifyButtonLoading = true;
      let phoneNumberInParts = this.userPhone.split(" ");
      if (phoneNumberInParts[0] == `+${this.dialCode}`) {
        phoneNumberInParts.shift();
        this.userPhone = phoneNumberInParts.join(" ");
      }

      let phone = {
        phoneNumber: Number(this.userPhone.replace(/\D/g, "")),
        countryCode: Number(this.dialCode),
        password: String(this.code),
      };

      let response = await UserApi.verifyChangedPhone(phone);

      this.$store.dispatch("setChangeVerificationMessage", response.message);

      if (response.status == 200) {
        this.$store.dispatch("successfullChangeVerification");
        this.$store.dispatch("setUserData", response.user);
        this.onSuccessfullVerification();
      } else {
        this.$store.dispatch("failedChangeVerification");
      }

      this.verifyButtonLoading = false;
    },

    countryChanged: function (newCountry) {
      this.dialCode = newCountry.dialCode;
    },
  },
};
</script>

<style scoped>
.hide {
  display: none;
  visibility: hidden;
}

#phone-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.green-brand {
  background: linear-gradient(62deg, #198b4a, #04da6f);
}

.dark-brand {
  background: linear-gradient(121deg, #003340, #0b4d5e);
}

#change-phone-container,
#verification-container {
  position: fixed;
  top: 45%;
  left: 50%;
  height: 60vh;
  min-height: 350px;
  max-height: 680px;
  width: 400px;
  background: #f7f7f7;
  color: gray;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 207;
  transform: translate(-50%, -50%);
}

#verification-container {
  height: 75vh;
  width: 23.5vw;
}

#change-phone-number-button {
  height: 5vh;
  width: 90%;
  min-height: 40px;
  max-height: 55px;
  margin-top: 16px;
  margin-left: 8%;
  border-radius: 0 16px 16px 0;
  box-shadow: unset;
  border: none;
  background: #198b4a;
  color: white;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

@media screen and (min-height: 1080px) {
  #change-phone-container,
  #verification-container {
    height: 50vh;
  }
}
@media screen and (max-width: 1018px) {
  #change-phone-container,
  #verification-container {
    width: 85vw !important;
    max-width: 380px;
    height: 107vw;
    max-height: 450px;
    min-height: 240px;
  }

  #verification-container {
    height: 125vw;
    min-height: 400px;
  }

  #change-phone-number-button {
    margin-left: 10%;
    width: 100%;
    max-width: 310px;
  }
}

@media screen and (max-width: 260px) {
  #change-phone-container,
  #verification-container {
    height: 80vh;
  }
}

#change-phone-label,
#verification-label {
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-weight: 700;
  width: 100%;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.16), 0 2px 1px rgba(0, 0, 0, 0.23);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
@media screen and (max-width: 1018px) {
  #verification-label {
    flex: 0.7;
  }
}

@media screen and (max-width: 1018px) and (max-height: 580px) {
  #change-phone-label,
  #verification-label {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    flex: none;
    height: 16.5%;
    min-height: 100px;
    max-height: unset;
    overflow: hidden;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

#change-phone-label > h4,
#verification-label > h4 {
  font-size: 18px;
  margin-top: 1vh;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1018px) {
  #change-phone-label > h4,
  #verification-label > h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 1018px) and (max-height: 580px) {
  #change-phone-label > h4,
  #verification-label > h4 {
    margin: 0 !important;
    width: 100%;
  }
}

#close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #eff;
  font-size: 17px;
}

#verification-phone-number {
  font-weight: 700;
  color: #198b4a;
}

#change-phone-form,
#verification-form {
  flex: 1.5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  color: gray;
}

@media screen and (max-width: 330px) {
  #change-phone-form {
    flex: 1.3 !important;
  }
}
@media screen and (max-width: 1018px) and (max-height: 580px) {
  #change-phone-container,
  #verification-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw !important;
    height: 100%;
    min-height: unset;
    max-height: unset;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 0;
    top: 50% !important;
    left: 50%;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 270px) {
  #change-phone-number-button {
    width: 90%;
  }
}

#verification-code {
  display: flex;
  width: 85%;
  align-self: center;
}

#verification-code-input {
  width: 62%;
  margin-left: 2.5%;
  border: none;
  -webkit-box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  text-align: right;
  padding-right: 10px;
  padding-left: 5px;
  min-height: 30px;
  font-size: 17px;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 0vh;
  -webkit-appearance: none !important;
}

#verification-form {
  flex: 2.6;
}

@media screen and (max-width: 1018px) {
  #verification-form {
    flex: 2.3 !important;
  }
}

@media screen and (max-width: 1018px) and (max-height: 580px) {
  #verification-form {
    flex: unset !important;
    height: 100%;
    min-height: 350px;
  }

  #change-phone-form {
    flex: unset !important;
    height: 100%;
    min-height: 250px;
  }
}

.extra-verification-option {
  color: #333;
  border-bottom: 1px solid #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-left: 9%;
  cursor: pointer;
}
@media screen and (max-width: 1018px) {
  .extra-verification-option {
    font-size: 13px;
  }
}
@media screen and (max-width: 330px) {
  .extra-verification-option {
    font-size: 12px;
  }
}
@media screen and (max-width: 300px) {
  .extra-verification-option {
    font-size: 11px;
  }
}

#phone-label > h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

#phone-label {
  margin-left: 7.5%;
}

input,
#phone-input {
  margin-left: 8%;
  background: #f7f7f7;
  width: 90%;
  height: 4vh;
  min-height: 25px;
  outline: none;
  border: none;
  border-bottom: 1.5px solid #198b4a;
  -webkit-appearance: none !important;
}
@media screen and (max-width: 1018px) {
  input,
  #phone-input {
    font-size: 14px;
    outline: none;
    width: 66vw;
    max-width: 310px;
    margin-left: 10%;
  }
}

.failed {
  border-bottom: 1.5px solid #f30;
}

#country-code {
  width: 10%;
  margin-right: 5%;
  margin-left: 1%;
}

#plus {
  margin: 0;
  padding: 0;
}

#verification-submit-button {
  height: 4vh;
  min-height: 30px;
  border-radius: 0 16px 16px 0;
  box-shadow: unset;
  border: none;
  background: #198b4a;
  color: white;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

#verification-submit-button:disabled {
  background: gray;
}

#sent-code-label {
  text-align: center;
  margin: 0;
  padding: 0 7.5% 0 7.5%;
  color: #333;
  font-size: 16px;
}
@media screen and (max-width: 1018px) {
  #sent-code-label {
    font-size: 14px;
  }
}
@media screen and (max-width: 330px) {
  #sent-code-label {
    font-size: 13px;
  }
}
@media screen and (max-width: 300px) {
  #sent-code-label {
    font-size: 12px;
  }
}

.attemptFailedPhoneChange,
.attemptSucceededPhoneChange {
  width: 100%;
  margin-top: 8px;
  margin-left: 8%;
}

@media screen and (max-width: 1018px) {
  .attemptFailedPhoneChange,
  .attemptSucceededPhoneChange {
    margin-left: 10%;
  }
}

.attemptFailedPhoneChange > h6 {
  font-size: 14px;
  font-weight: 600;
  color: #f30;
  white-space: pre-wrap;
  margin-bottom: 0;
}

.attemptFailedVerification,
.attemptSucceededVerification {
  width: 100%;
  margin-left: 10%;
}

.attemptFailedVerification > h6 {
  font-size: 14px;
  color: #f30;
  white-space: pre-wrap;
  margin-bottom: 0;
}

.attemptSucceededPhoneChange > h6 {
  font-size: 14px;
  font-weight: 600;
  color: #198b4a;
  width: 50%;
  margin-bottom: 0;
}

.attemptSucceededVerification > h6 {
  font-size: 14px;
  color: #198b4a;
  width: 50%;
  margin-bottom: 0;
}

.hide {
  display: none;
}

.visibleModal {
  visibility: visible;
}

.hiddenModal {
  visibility: hidden;
}

.initialHidenModal {
  visibility: hidden;
}

.hyperlink {
  cursor: pointer;
  color: #6277ff;
  margin-left: 6px;
  border-bottom: 1px solid #6277ff;
}

.slideOut {
  animation: slideOut 0.85s forwards ease-in-out,
    resetPosition 0s forwards 0.85s;
}

.slideIn {
  animation: slideIn 0.85s forwards ease-in-out 0.3s;
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%);
    visibility: unset;
  }

  to {
    opacity: 0;
    transform: translate(-50%, 30%);
    visibility: hidden;
  }
}

@keyframes resetPosition {
  from {
    transform: translate(-50%, 30%);
  }

  to {
    transform: translate(-50%, -130%);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(-50%, -130%);
    visibility: hidden;
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
    visibility: unset;
  }
}

@media screen and (max-width: 1018px) {
  #phone-label > h4 {
    font-size: 14px;
  }

  .attemptFailedPhoneChange > h6 {
    font-size: 13px;
  }

  .attemptFailedVerification > h6 {
    font-size: 13px;
  }

  #verification-submit-button {
    font-size: 14px;
  }
}
</style>